<template>
  <div>
    <div>{{productName}}</div>
    <ul v-if="multipleInstance && instances.length>0">
      <li
        v-for="instance in instances"
        :key="instance.id"
        class="grey--text text--darken-1"
      >
        {{instance.name}}
      </li>
    </ul>
  </div>
</template>

<script lang="babel" type="text/babel">
import listDataItem from '@/components/list/mixins/listDataItem'
export default {
  mixins: [listDataItem],
  data: () => ({

  }),
  computed: {
    productName() {
      return this.row.name
    },
    multipleInstance() {
      return this.row.multi_instances
    },
    instances() {
      if(!Array.isArray(this.row.instances)) return []
      return this.row.instances
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>